import styles from "./Translation.module.css";
import Item from "../Item";
import ApprovalStatus from "./ApprovalStatus";
import { useTextStyle } from "../../../hooks/useTextStyle";

interface Props {
  translationIcon: string;
  translationName: string;
  translationCode: string;
  translationLanguage: string;
  qualityChecks: string[];
  darkMode: boolean;
  isSelected: boolean;
  handler: () => void;
}

const Translation = ({
  translationIcon,
  translationName,
  translationCode,
  translationLanguage,
  qualityChecks,
  darkMode,
  isSelected,
  handler,
}: Props): JSX.Element => {
  const nameStyle = useTextStyle(
    {
      large: "2.1em",
      normal: "1.3em",
      small: "1.1em",
    },
    !isSelected
  );

  const languageStyle = useTextStyle(
    {
      large: "1.3em",
      normal: "0.95em",
      small: "0.8em",
    },
    !isSelected
  );

  return (
    <Item
      id={translationCode}
      handler={handler}
      isSelected={isSelected}
      darkMode={darkMode}
    >
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <img
            crossOrigin="anonymous"
            src={translationIcon}
            alt=""
            className={styles.icon}
          />
        </div>
        <div className={styles.textContainer}>
          <div style={nameStyle} className={styles.nameContainer}>
            {translationName}
          </div>
          <div style={languageStyle} className={styles.languageContainer}>
            {translationLanguage}
          </div>
        </div>
        {qualityChecks.length > 0 && (
          <ApprovalStatus
            darkMode={darkMode}
            isSelected={isSelected}
            hasChurchApproval={qualityChecks.some(
              (check) => check === "church"
            )}
            hasConsultantApproval={qualityChecks.some(
              (check) => check === "consultant"
            )}
          />
        )}
      </div>
    </Item>
  );
};

export default Translation;
